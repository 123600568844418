define(['componentHelper', '$window', 'app', 'accessibleModalHelper'], (componentHelper, $window, app, accessibleModalHelper) => {
  const htmlToNode = (html) => {
    const tmpEl = document.createElement('div');
    tmpEl.innerHTML = html;
    return tmpEl.firstChild;
  };

  const fixBodyScroll = () => {
    const {top, left, position, width} = document.body.style;

    Object.assign(document.body.style, {
      top: `-${$window.scrollY}px`,
      left: `-${$window.scrollX}px`,
      width: '100vw',
      position: 'fixed',
    });

    return () => {
      const prevScrollY = -1 * Number.parseFloat(document.body.style.top);
      const prevScrollX = -1 * Number.parseFloat(document.body.style.left);
      Object.assign(document.body.style, {top, left, width, position});
      $window.scrollTo(prevScrollX, prevScrollY);
    };
  };

  const modal = () => {
    const comp = {};

    comp.init = (element) => {
      comp.element = element;
      comp.closeSubscription = null;
      comp.reloadSubscription = null;
      comp.children = {};
      comp.children.trigger = element.querySelector('.modal_trigger');
      comp.clickAndCollectModal = element.querySelector('.v3ResponsiveBasket_clickAndCollect_trigger');

      comp.appendDialogToBody();
      comp.addListeners();

      return comp;
    };

    comp.ensureTitleARIAIDsAreUnique = () => {
      const modalTitleId = comp.children.dialogContent.getAttribute('aria-labelledby');
      if(modalTitleId) {
        const title = comp.children.dialogContent.querySelector(`#${modalTitleId}`);

        if (title) {
          const uniqueId = $window.getUUID(modalTitleId);
          comp.children.dialogContent.setAttribute('aria-labelledby', `${uniqueId}`);
          title.setAttribute('id', `${uniqueId}`);
        } else {
          comp.children.dialogContent.removeAttribute('aria-labelledby');
        }
      }
    };

    comp.appendDialogToBody = () => {
      const dialogTemplate = comp.element.querySelector('.modal_dialog_template');
      const dialogHTML = dialogTemplate.innerHTML.trim();

      const dialog = htmlToNode(dialogHTML);
      const dialogContent = dialog.querySelector('.modal_dialog_content');
      const dialogClose = dialog.querySelectorAll('.modal_dialog_close');
      Object.assign(comp.children, {dialog, dialogContent, dialogClose});
      comp.ensureTitleARIAIDsAreUnique();

      dialogTemplate.remove();
      document.body.appendChild(dialog);
      componentHelper.reloadAllComponents(dialog);
    };

    comp.addListeners = () => {
      comp.children.trigger.addEventListener('click', comp.showPopup);
      comp.children.dialog.addEventListener('click', comp.hidePopup);
      comp.children.dialogClose.forEach(button => {
        button.addEventListener('click', comp.hidePopup);
      })
      comp.children.dialogContent.addEventListener('click', e => e.stopPropagation());
    };

    comp.unfixBodyScroll = () => {
    };

    comp.showPopup = (e) => {
      if (comp.children.trigger.hasAttribute('data-dont-propagate')) {
        e.stopPropagation();
      }

      if(comp.clickAndCollectModal) {
        comp.children.dialog.classList.add('clickAndCollectInfoModal');
      }

      comp.children.dialog.classList.add('modal_dialog-show');
      comp.unfixBodyScroll = fixBodyScroll();

      // Note: the close functionality doesn't support multiple modals at the same time
      comp.closeSubscription = app.subscribe('modal/close', comp.hidePopup);

      const modalContent = comp.children.dialogContent;
      const modalHeading = modalContent.querySelector('h2');
      comp.accessibleModalHelper = new accessibleModalHelper(modalContent, comp.hidePopup, modalHeading);
      comp.reloadSubscription = app.subscribe('modal/reloadAccessibleModalHelper',
        target => comp.accessibleModalHelper.reload(modalContent, target),
      );

    };

    comp.hidePopup = () => {
      comp.children.dialog.classList.remove('modal_dialog-show');
      comp.unfixBodyScroll();

      if (comp.closeSubscription) {
        comp.closeSubscription.remove();
        comp.closeSubscription = null;
      }

      if (comp.reloadSubscription) {
        comp.reloadSubscription.remove();
        comp.reloadSubscription = null;
      }

      comp.accessibleModalHelper && comp.accessibleModalHelper.close();

      app.publish('modal/modalClosed');
    };

    return comp;
  };

  return modal;
});
